import { Box, Typography } from '@mui/material';
import React from 'react';
import { fTimeStamp } from '../../../utils/formatTime';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const UserChatBoxMessage = ({
	chat,
	user = false,
	read,
	last,
	lastFromReader,
	onPress
}) => {
	const { createdAt, fromAdmin, text } = chat.data;

	const markdownLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

	// Convert Markdown links into <a> tags
	const formattedText = text.split('\n').map((line, index) => {
		// Replace Markdown links with <a> tags
		const lineWithLinks = line.replace(markdownLinkRegex, (match, p1, p2) => {
			return `<a href="${p2}" target="_blank" rel="noopener noreferrer">${p1}</a>`;
		});

		return (
			<span key={index}>
				<span dangerouslySetInnerHTML={{ __html: lineWithLinks }} />
				<br />
			</span>
		);
	});

	return (
		<>
			<Box
				onClick={onPress}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems:
            user === true
            	? !fromAdmin
            		? 'flex-end'
            		: 'flex-start'
            	: !fromAdmin
            		? 'flex-start'
            		: 'flex-end',
					mt: 0.5
				}}
			>
				<Box
					sx={{
						backgroundColor:
              user === true
              	? fromAdmin
              		? 'background.neutral'
              		: 'primary.light'
              	: fromAdmin
              		? 'primary.light'
              		: 'background.neutral',
						maxWidth: '70%',
						borderRadius: '5px',
						color: 'text.main',
						px: 0.5,
						pt: 0.5,
						pb: 2,
						whiteSpace: 'normal',
						overflowWrap: 'break-word',
						position: 'relative',
						minWidth: '40px'
					}}
				>
					{formattedText}
					<Typography
						variant='smallBody'
						sx={{ position: 'absolute', bottom: 0, right: 5 }}
					>
						{createdAt !== null && fTimeStamp(createdAt.seconds * 1000)}
					</Typography>
				</Box>
				{last && lastFromReader && (
					<RemoveRedEyeIcon
						fontSize='5px'
						color={read ? 'primary' : 'disabled'}
					/>
				)}
			</Box>
		</>
	);
};
export default UserChatBoxMessage;